import { useMeDetails } from "./user";
import { useCurrentMeeting } from "./meeting";
import { meetingService } from "@/core/services";
import { useQuery } from "@tanstack/vue-query";

export const useMeetingAccessRights = () => {
  const userId = useMe().value ?? "";
  const meetingId = useMeetingId();
  return useQuery({
    queryKey: ["access_rights", userId, meetingId],
    queryFn: () =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsPublicAccessRightsRetrieve({
          uuid: meetingId,
        }),
  });
};

export const useUserAccessRights = () => {
  const userId = useMe().value ?? "";
  return useQuery({
    queryKey: ["access_rights", userId],
    queryFn: () =>
      meetingService.getInstance().getUsersApi().usersAccessRightsRetrieve({
        uuid: userId,
      }),
  });
};

export const usePermission = () => {
  const meetingId = useMeetingId() ?? null;
  const { data: meetingAccessRights } = useMeetingAccessRights();
  const { data: userAccessRights } = useUserAccessRights();
  const { data: user } = useMeDetails();
  const { data: currentMeeting } = usePublicMeeting();

  const store = useUserStore();

  const { setModules, setMeetingPermissions, setUserPermissions } = store;

  watch(userAccessRights, () => {
    if (user.value && userAccessRights.value) {
      setUserPermissions(
        new Set([...(userAccessRights.value?.access_rights ?? [])]),
        user.value.is_superuser
      );
    }
  });

  watch(user, () => {
    if (user.value && userAccessRights.value) {
      setUserPermissions(
        new Set([...(userAccessRights.value?.access_rights ?? [])]),
        user.value.is_superuser
      );
    }
  });

  watch(meetingAccessRights, () => {
    if (meetingId != null && meetingAccessRights.value) {
      setMeetingPermissions(
        new Set([...(meetingAccessRights.value?.access_rights ?? [])]),
        meetingId
      );
    }
  });

  watch(currentMeeting, () => {
    if (currentMeeting.value) {
      setModules(
        new Set(
          currentMeeting.value?.modules.map((module) => module.codename) ?? []
        ),
        meetingId
      );
    }
  });

  const { hasPermission } = storeToRefs(store);

  return hasPermission;
};
